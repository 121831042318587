import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';

import SMotionStyle from './s-motion.module.css';
import Axios from 'axios';
import sm_thumbnail from '../assets/S-Motion-logo.jpg';
import { connect } from 'react-redux';
import { add_to_cart } from '../redux/cart.redux';
import SMotionDescription from '../components/SMotionDescription';


class SMotionPage extends React.Component {
  constructor() {
    super();
    this.state = {
      emailInput: "",
      value: 0,
      isMobile: true,
    }
    this.handleInputChange = this.handleInputChange.bind(this);

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTrackEvent = this.handleTrackEvent.bind(this);
  }

  handleTrackEvent(evt) {
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('trackCustom', evt);
    }
  }

  handleResize = e => {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
  }

  componentDidMount() {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }





  

  handleInputChange(event) {
    this.setState({ quantity: event.target.value });
  }


  handleEmailInput(event) {
    this.setState({emailInput: event.target.value});
  }

  handleSubmit(event) {
    // console.log(this.state.emailInput);
    
    if (window.fbq != null) {
      window.fbq('track', 'AddToWishlist');
    }

    let emailValidator = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!emailValidator.test(this.state.emailInput)) {
      alert("Please enter a valid email")
      return
    }
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    let data = {email: this.state.emailInput}
    Axios.post('https://api.sonic-instruments.co/api/maillist', data, config).then(function(response) {
        // obj.displayAlert('Thanks for reaching out, we will get back to you shortly!')
        alert("Awesome! You have joined the waiting list.")
      })
      .catch(function(err) {
        // obj.displayAlert(`Oops, something went wrong... ${err}`)
        alert("Oops" + err)
      })
  }








  

  render() {

    const bulletin = (
         <div style={{marginBottom: '24px', marginTop: '24px'}}>Any Questions? <a style={{textDecoration:'underline'}} href="mailto:info@sonichits.co?Subject=Question%20about%20MO-Band">Shoot us an email</a></div>
    )

    
    return (
      <Layout location={this.props.location} title={'S-Motion'}>
        <SEO
          title={'S-Motion - Translate motion into midi'}
          keywords={[`music`, `wearable`, `midi`, `motion`, `controller`]}
          description={'S-Motion is the software for MO-Band, it translates your hand motion into midi control messages.'}
          image={sm_thumbnail}
        />
        <SMotionDescription />
        <div className={SMotionStyle.container}>
            <div className={SMotionStyle.contentContainer}>
                {bulletin}
                <form>
                  <p className={SMotionStyle.deliveryTime}>Join our email list to get a chance to win $50 off on MO-Band.</p>
                  <input type="text" name="email" placeholder="Email" className={SMotionStyle.emailInput} onChange={this.handleEmailInput}/>
                  <input type="button" value="Submit" className={SMotionStyle.submitBtn} onClick={this.handleSubmit}/>
                </form>
                <br/>
                {/* {paymentSuccess && <p>Thanks for purchasing!</p>} */}
            </div>
        </div>
      </Layout>
    )
  }
}

const actionCreators = { add_to_cart }

export default connect(null, actionCreators)(SMotionPage)

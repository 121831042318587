import SMDStyle from './SMotionDescription.module.css';
import SMotionUI from '../assets/S-Motion-ui.png';
import WheelDemo from '../assets/wheel.mp4';
import dataMonitor from '../assets/dataMonitor.mp4';
import presets from '../assets/presets.png';
import mapping from '../assets/mapping.mp4';
import React from 'react';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DAWs from '../assets/daws.png'
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from 'gatsby';


class SMotionDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SMDStyle.container}>
                <div className={SMDStyle.titleContainer}>
                    <h2>S-Motion</h2>
                    <h3>Translates MO-Band's Motion Data into MIDI</h3>
                </div>
                <div style={{height: "80px"}}/>
                <ScrollAnimation animateIn='slideInUp' animateOnce={false}>
                    <div className={SMDStyle.content}>
                        <h4>What is S-Motion for</h4>
                        <p>S-Motion is the brain for MO-Band, it breaks down motion into 3 axes (x, y, z), and lets you configure how your movements in each axis will affect the music.</p>
                    </div>
                </ScrollAnimation>
                <div className={SMDStyle.contentContainer}>
                    <div className={SMDStyle.uiImg}>
                        <img src={SMotionUI} width="100%"/>
                    </div>
                </div>
                <div style={{height: "80px"}}/>
                <ScrollAnimation animateIn='pulse' animateOnce={false}>
                    <div className={SMDStyle.wheelContainer}>
                        <div className={SMDStyle.content}>
                            <h4>MIDI wheels</h4>
                            <p>MIDI wheels are the core components in S-Motion, just like the knobs on a MIDI controller, they send out the MIDI control signal. Each wheel is fully configurable, you can choose the motion axis to use, adjust the range of motion, and set MIDI channel and CC number.</p>
                        </div>
                        <div className={SMDStyle.wheelVideo}>
                            <video loop muted autoPlay playsInline crossOrigin="anonymous" width={"200px"} id="wheelVid">
                                <source src={WheelDemo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </ScrollAnimation>
                <div style={{height: "120px"}}/>
                <ScrollAnimation animateIn='fadeIn' animateOnce={false}>
                    <div className={SMDStyle.contentLong}>
                        <h4>Instant Mapping</h4>
                        <p>S-Motion features a "Mapping mode", it allows you to map the output from MIDI wheels to your DAW with just one click. The wheels will pause their outputs when in mapping mode, this eliminates the chance of accidental mapping. You can access mapping mode by click on the icon on the topbar, or just double press the bypass button on your MO-Band.</p>
                    </div>
                    <div className={SMDStyle.contentContainer}>
                        <video loop muted autoPlay playsInline crossOrigin="anonymous" className={SMDStyle.uiImg} id="uiVid">
                            <source src={mapping} type="video/mp4" />
                        </video>
                    </div>
                </ScrollAnimation>
                <div style={{height: "120px"}}/>
                <ScrollAnimation animateIn='slideInDown' animateOnce={false}>
                    <div className={SMDStyle.deviceConfigContainer}>
                        <div className={SMDStyle.columnContent}>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <h4>Motion Visualizer</h4>
                                <p>The visualizers on the top right corner show you how your movements get break down into x, y, z, as well as "G" for the motion velocity. They make it super intuitive for you to determine which axis(axes) to use.</p>
                                <video loop muted autoPlay playsInline crossOrigin="anonymous" width="100%" id="wheelVid" style={{marginTop: '12px', maxWidth:"300px"}}>
                                    <source src={dataMonitor} type="video/mp4" />
                                </video>
                            </div>
                            <div style={{margin: "auto", padding: '16px'}}>
                                <h4>Save & Load Presets</h4>
                                <p>There are 16 slots for you to save your patch. They can be recalled instantly by click on the slot, and slot 1-8 can also be recalled by keyboard shortcut Ctrl(⌘) + 1 to 8.</p>
                                <img src={presets} width="100%" style={{marginTop: '12px'}} />
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
                <div style={{height: "120px"}}/>
                <ScrollAnimation animateIn='fadeIn' animateOnce={false}>
                    <div className={SMDStyle.contentLong}>
                        <h4>Full-on MIDI central</h4>
                        <p>Besides being the brain for MO-Band, S-Motion can very well be the central to link all your MIDI-compatible software and hardware together. The MIDI wheels can send messages to multiple endpoints simultaneously, which means it can control both your DAWs and hardware at the same time. Same as the transport controls, midi clock, and virtual MIDI keyboard. This opens up a possibility for you to set up a motion-controlled MIDI network, every device in the network is always in-sync.</p>
                    </div>
                    <div style={{height: "80px"}}/>
                    <div className={SMDStyle.content}>
                        <h4>Comprehensive compatability</h4>
                        <p>S-Motion is compatible with Windows 7 or newer, and macOS 10.13.6 or newer (10.15 is supported). It works great with any major DAW such as Logic Pro, Ableton Live, Cubase/Nuendo, Pro Tools, GarageBand, FL Studio, Studio One, SONAR, and Reaper.</p>
                        <div style={{marginTop: '1rem', display: 'flex'}}>
                            <FontAwesomeIcon icon={faWindows} size="2x" style={{marginRight: '32px'}}/>
                            <FontAwesomeIcon icon={faApple} size="2x" style={{marginRight: '8px'}}/>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='pulse' animateOnce={false}>
                    <div className={SMDStyle.contentContainer}>
                        <div className={SMDStyle.dawIcons}>
                            <img src={DAWs} width="80%"/>
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='slideInUp' animateOnce={false}>
                    <div style={{textAlign: 'center'}}>
                        <Link to="mo-band"><button style={{width: 'auto', padding: '6px 32px 6px 32px', borderColor: "transparent", marginBottom: "5rem"}}>About MO-Band</button></Link>
                    </div>
                </ScrollAnimation>
            </div>   
        )
    }
}

export default SMotionDescription